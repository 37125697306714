import React from 'react';
import { DateOfBirth } from '../UI/DateofBirth';
import { useForm } from 'react-hook-form';
import Button from '../UI/Button';
import { useHistory } from 'react-router-dom/cjs/react-router-dom';
import { Months } from '../../Constants/Constants';
import { useRingbaInfo } from "../../Hooks/useRingbaInfo";

const DobModal = ({ dobModal,queryString, uuid}) => {
    const { register, errors, trigger, setError, clearErrors, getValues } =
    useForm({
      mode: "onBlur",
      reValidateMode: "onChange",
      defaultValues: {},
      resolver: undefined,
      context: undefined,
      criteriaMode: "firstError",
      shouldFocusError: true,
      shouldUnregister: true,
    });
    const { postRingbaDob } = useRingbaInfo();
    const history = useHistory();
    const restuctureDob = (values) =>{
        const month = Months.indexOf(values.DobMonth)+1;
        return `${month}-${values.DobDay}-${values.DobYear}`;
    }
    

    const handleClick = async () => {
        const isValid = await trigger(); 
        if (!isValid) return; 
        const values = getValues();
        values.uuid = uuid;
        let dob = restuctureDob(values);
        const dobnew = new Date(`${values.DobYear}-${values.DobMonth}-${values.DobDay}`);
        const today = new Date();
        let age = today.getFullYear() - dobnew.getFullYear();
        const monthDiff = today.getMonth() - dobnew.getMonth();
        if (monthDiff < 0 || (monthDiff === 0 && today.getDate() < dobnew.getDate())) {
            age--;
        }
        if(values.DobDay && values.DobMonth && values.DobYear  ){
            const response = await postRingbaDob(values);
            const queryString = (window.location.href.split('?')[1] || '').trim();
            var redirectUrl = `${window.location.origin}/medicare-thankyou?${queryString}&age=${age}`;
            window.location.href = redirectUrl;
        }

 

    }
    


    return (
        <>
            <div
                className={`modal fade unqlifiedmodal-pop ${dobModal} `}
                tabIndex={-1}
                role='dialog'
                style={{ backgroundColor: 'rgba(0, 0, 0, 0.95)' }}>
                <div
                    className='modal-dialog modal-dialog-centered modal-md'
                    role='document'>
                    <div className='modal-content bor-b5'>
                        {/* <div className='modal-header noborder' /> */}
                        <div className='modal-body'>
                            <p className='unqualify-modal-p text-center fw-bold mt-4' style={{fontSize:"14px"}}>
                            ¡Buenas noticias! Usted califica para algunas ofertas fantásticas. ¡Confirma tu fecha de nacimiento a continuación para revelarla! 🤩🤩🥳🥳🥳
                            </p>
                            <p className='text-center'>
                            <DateOfBirth
                              validation={register}
                              validationMsg={errors}
                              textDob="Fecha de nacimiento"
                              clearErrors={clearErrors}
                              />
                            </p>
                            <div className='text-center py-2'>
                                <Button
                                    type="button"
                                    onClick={handleClick}
                                    buttonText="Siguiente"  
                                    className="btn btn-success"            
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default DobModal;