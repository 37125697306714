// import { lazy } from 'react';
// const Split_1 =  import('./Components/Pages/Splits/Split_1');

// export {
//     Split_1
// }

import React, { lazy } from 'react';

const SPH_CDV2 = lazy(() => import('./Components/Pages/SPH_CDV2/SPH_CDV2'));
const SPH_CDV1 = lazy(() => import('./Components/Pages/SPH_CDV1/SPH_CDV1'));
const SPH_CDV2_1 = lazy(() => import('./Components/Pages/SPH_CDV2_1/SPH_CDV2_1'));
const SPH_CDV2_2 = lazy(() => import('./Components/Pages/SPH_CDV2_2/SPH_CDV2_2'));
const Partners_list = lazy(() => import('./Components/Pages/Partners_list/Partners_list'));
const SPH_CDV3 = lazy(() => import('./Components/Pages/SPH_CDV3/SPH_CDV3'));
const SPH_CDV6B_MS = lazy(() => import('./Components/Pages/SPH_CDV6B_MS/SPH_CDV6B_MS'));
const SPH_CDV4 = lazy(() => import('./Components/Pages/SPH_CDV4/SPH_CDV4'));
const SPH_CDV8_4_1 = lazy(() => import('./Components/Pages/SPH_CDV8_4_1/SPH_CDV8_4_1'));
const SPH_CDV_S3_1 = lazy(() => import('./Components/Pages/SPH_CDVS31/SPH_CDVS31'));
const SPH_CDV_S10 = lazy(() => import('./Components/Pages/SPH_CDV_S10/SPH_CDV_S10'));

const AppSplitList = (props) => {

    const SplitListMap = {
        'SPH_CDV2': SPH_CDV2,
        'SPH_CDV1': SPH_CDV1,
        'SPH_CDV2_1': SPH_CDV2_1,
        'SPH_CDV2_2': SPH_CDV2_2,
        'partners-list': Partners_list,
        'SPH_CDV3': SPH_CDV3,
        'SPH_CDV6B_MS': SPH_CDV6B_MS,
        'SPH_CDV4':SPH_CDV4,
        'SPH_CDV8_4_1' :SPH_CDV8_4_1,
        'SPH_CDV_S3_1' : SPH_CDV_S3_1,
        'SPH_CDV_S10' : SPH_CDV_S10
    };
    const SplitComponent = SplitListMap[props.splitName];
    return (
        <>
            {SplitComponent && <SplitComponent />}
        </>
    )

}

export default AppSplitList;