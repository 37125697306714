import React, { useState } from 'react';
// import loader from "../../../src/assets/img/ACA_SPH_V5/loader.gif";
import loader from "../../../assets/img/SPH_CDV8_4_1/loader.gif";

const SimpleModal = ({ modalBody, setModalBody, ModalViewers, showLoader, buttonText }) => {
    return (
        <>
            <div
                className={`modal fade unqlifiedmodal-pop ${modalBody === '' ? 'hide' : 'show'}`}
                tabIndex={-1}
                role='dialog'
                style={{ backgroundColor: 'rgba(0, 0, 0, 0.95)' }}>
                <div
                    className='modal-dialog modal-dialog-centered modal-md'
                    role='document'>
                    <div className='modal-content bor-b5'>
                        {/* <div className='modal-header noborder' /> */}
                        <div className='modal-body' style={{ fontSize: '14px' }}>
                            <p className='unqualify-modal-p text-center fw-bold mt-4'>{modalBody}</p>
                        </div>
                        <div className='modal-footer justify-content-center'>
                            {(!showLoader) ? <button
                                type='button'
                                className='btn btn-secondary btn-anime btn_reClaim'
                                data-dismiss='modal'
                                onClick={(e) => { ModalViewers(1) }}>
                                {buttonText}
                            </button> : <div className="msg-loader loader1">
                                <div className="text-msg">
                                    <img src={loader} alt="" />
                                </div>
                            </div>
                            }
                        </div>

                    </div>
                </div>
            </div>
        </>
    );
};

export default SimpleModal;
