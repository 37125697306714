import React from 'react';
import { Helmet } from 'react-helmet';

const Tapstone = () => {
console.log("testTapstone");
  return (
    <>
    <div>
      <Helmet>
        <script type="text/javascript">
          {`function getUrlVars() {
              var vars = {};
              var parts = window.location.href.replace(/[?&]+([^=&]+)=([^&]*)/gi,    
              function(m,key,value) {
                vars[key] = value;
              });
              return vars;
          }
          var urlVars = getUrlVars();`}
        </script>
        <script type="text/javascript">
          {`var din_params_1764= {
            //'subid': 'YOUR_VALUE', Ex: 1234_5678, 1234_5678_980
            //'shuffle': 'YOUR_VALUE', //
              'refid': urlVars['subid'] || '', //
              'tag2': urlVars['src'] , //
              'tag3': urlVars['src2'] , //
              'pixelkey':'clickid', 
              'pixelval': urlVars['clickid'] || ''
          };`}
        </script>
        <script async src="//adserv.convertingtraffic.com/www/delivery/asyncjs.php"></script>
      </Helmet>
      <ins data-revive-zoneid="1764" data-revive-id="e2512d2b571194f68f176aec0585f389"></ins>
    </div>
    </>
  );
};

export default Tapstone;