//     extra parameters     //
//     ----------------     //
//  -> first_question       //
//  -> final_question       //
//  -> specialcss           //
//////////////////////////////

export const QandA_v8_4_1 = [
  {
    id: "7",
    first_question: true,
    question: [`Hola!`, `¿Quieres ver si calificas?`],
    options: [{ id: "14", text: "Si, Vamos!" }],
  },
  {
    id: "8",
    question: [`¿Ya tienes Seguro Medico, Medicare o Medicaid?`],
    options: [
      { id: "17", text: "No" },
      { id: "16", text: "Si" },
    ],
  },
  // If Si, then below question else skip to last
  // show popup for every below option
  {
    id: "9",
    question: [`¿Cuál de los siguientes tienes?`],
    specialcss: "verticleButton",
    options: [
      { id: "18", text: "Medicare" },
      { id: "19", text: "Medicaid" },
      { id: "20", text: "Medicare & Medicaid" },
      { id: "21", text: "VA" },
      // { id: '34', text: "Other" },
    ],
  },
  {
    id: "10",
    question: [
      `¿Eres Ciudadano o Residente, tienes Green Card, tienes Permiso para Trabajar o estás en Proceso Migratorio?`,
    ],
    final_question: true,
    options: [
      { id: "22", text: "Si" },
      { id: "23", text: "No" },
      // show popup for no
    ],
  },
  {
    id: "CALL_CLICK",
    question: [
      `¡Felicidades! 🤩🤩🥳🥳🥳`,
      `Estás precalificado para recibir hasta $6,800 en subsidios fiscales para tu seguro médico.`,
      `Puedes utilizar los ahorros para las facturas del hogar, el entretenimiento, los viajes y el ocio!`,
      `Toca el botón de abajo para llamar ahora y reclama tus beneficios, sólo te llevará de 2 a 3 minutitos.`,
    ],
    options: [{ id: "", text: "833-506-3463" }],
  },
];

export const QandA_SPH_CDV6B_MS = [
  {
    id: '7',
    first_question: true,
    question: [
      `Hola!`,
      `¿Quieres ver si calificas?`
    ],
    options: [
      { id: '14', text: "Si, Vamos!" }
    ],
  },
  {
    id: '2',
    question: [
      `¿Ya tienes Seguro Medico, Medicare o Medicaid?`
    ],
    options: [
      { id: '3', text: "No" },
      { id: '4', text: "Si" },
    ],
  },
  // If Si, then below question else skip to last
  // show popup for every below option
  {
    id: '9',
    question: [`¿Cuál de los siguientes tienes?`],
    specialcss: 'verticleButton',
    options: [
      { id: '18', text: "Medicare" },
      { id: '19', text: "Medicaid" },
      { id: '20', text: "Medicare & Medicaid" },
      { id: '21', text: "VA" },
    ],
  },
  {
    id: '10',
    question: [`¿Eres Ciudadano o Residente, tienes Green Card, tienes Permiso para Trabajar o estás en Proceso Migratorio?`],
    final_question: true,
    options: [
      { id: '22', text: "Si" },
      { id: '23', text: "No" },
      // show popup for no
    ],
  },
  {
    id: 'CALL_CLICK',
    question: [`¡Felicidades! 🤩🤩🥳🥳🥳`,
    `Estás precalificado para recibir hasta $6,800 en subsidios fiscales para tu seguro médico.`,
    `Puedes utilizar los ahorros para necesidades como comestibles, gasolina y lo que desees.`,
    `Toca el botón de abajo para llamar ahora y reclama tus beneficios.`, 
    `Sólo te llevará de 3 a 5 minutitos.`
    ],
    options: [
      { id: '', text: "833-506-3463" },
    ],
  },
];