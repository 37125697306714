import React from 'react'
import { Helmet } from "react-helmet";

const Header = () => {
  return (
    <div>
<Helmet><script src="//b-js.ringba.com/CA4f31afccd82d41f5a77defe4542dffab" async></script></Helmet>
<header className="py-2 d-none">
    <div className="container">
      <div className="row">
        <div className="col-lg-6">
          <a href="">
            <img
              height={50}
              src="https://yousted.org/lfdesigns/Sass-Projects/ACA_benefit_quiz/v3/dist/img/logo.png"
              alt=""
            />
          </a>
        </div>
        <div className="col-lg-6" />
      </div>
    </div>
  </header>
    </div>
  )
}

export default Header