import React, { useState, useContext, useEffect } from "react";
import Header from "../../Includes/Layouts/SPH_CDV3/Header";
import ChatBotBubble_SPH_CDV3 from "./ChatBotBubble_SPH_CDV3";
import { useDataIngestion } from "../../../Hooks/useDataIngestion";
import useJourneyStats from "../../../Hooks/useJourneyStats";
import AdtopiaLoadLP from "../../../Utility/AdtopiaLoadLP";
import VisitorParamsContext from "../../../Contexts/VisitorParams";
import { queryString } from "../../../Utility/QueryString";
import FbViewPixel from "../../../Utility/FbViewPixel";
import GetVisitorsParams from "../../../Utility/GetVisitorsParams";
import { useUserClicks } from '../../../Hooks/useUserClicks';
import { UUID } from "../../../Utility/UUID"
import { useRingbaInfo } from "../../../Hooks/useRingbaInfo";
import Footer from '../../Includes/Layouts/SPH_CDV1/Footer';

const SPH_CDV3 = (props) => {
  const splitName = 'SPH_CDV3';

  const { saveDataIngestion, isLoading } = useDataIngestion();
  const saveJournyStats = useJourneyStats();
  const { visitorParameters } = useContext(VisitorParamsContext);
  const { fetchUuid } = UUID();
  const uuid = fetchUuid();
  const { saveUserClicks } = useUserClicks();
  const { getRingbaInfo } = useRingbaInfo();
  const [response, setResponse] = useState("");
  const { postRingbaInfo } = useRingbaInfo();
  const campaign_name = "spanish";
  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await postRingbaInfo(campaign_name);
        setResponse(data.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, []);

  const saveUser = async (name, status) => {
    await saveUserClicks(uuid, name, status);
  };

  const formSubmit = async (values) => {
    const queryString = '';
    values.lstSalutation = '';
    values.txtFName = '';
    values.txtLName = '';
    values.txtEmail = '';
    values.txtPhone = '';
    values.page_name = splitName;
    const formSUbmitResult = await saveDataIngestion(
      visitorParameters.visitor_parameters,
      values,
      'split_form_submit',
      splitName,
      queryString,
      visitorParameters.data
    );
  };

  useEffect(() => {
    if (uuid) {
      (async () => {
        await saveJournyStats(uuid, 'question', '7', '', 'view', splitName);
      })();
    }
  }, [uuid]);

  const journeyStatus = async (values) => {
    const { slide_type, slide_question, slide_value, type } = values;

    await saveJournyStats(uuid, slide_type, slide_question, slide_value, type, splitName);
  };

  return (
    <>

      <AdtopiaLoadLP PageType="LP" splitName="SPH_CDV3" />
      <FbViewPixel />
      <div className="SPH_CDV3">
        <Header />
        {/* HEADER  */}
        <section>
          <div className="container text-center">
            <h5 className="mt-lg-4 mt-md-4 mt-sm-2 mt-2   text1">
              <span className="text-danger">Atención: </span>Los agentes están
              extremadamente ocupados. Por favor, no llames a menos que tengas
              de 3 a 5 minutos para reclamar tus beneficios.
            </h5>
            <div className="row mt-3">
              <div className="col-lg-10 offset-lg-1">
                <h1 className=" fw-bold text2">
                  Desbloquea hasta $6200 en subsidios fiscales para el seguro
                  médico. ¡Los latinos están utilizando los ahorros para
                  comestibles, gasolina, alquiler y más!
                </h1>
              </div>
            </div>
          </div>
        </section>
        <section className="chat-section mb-5 mrgn-btm">
          <div className="container">
            <div className=" mt-0 mb-lg-5 mb-md-5 mb-sm-3 mb-3">
              <div className="chat-wrap">
                <div className="mb-1 support-online py-3 text-center">
                  {" "}
                  <span className="circle pulse green me-1" /> Veronica esta
                  disponible{" "}
                </div>
                <div className="chat-box-inner">
                  {/* Chat rows */}
                  <div className="row-chart-message mb-4 ">
                    <ChatBotBubble_SPH_CDV3
                      formSubmit={formSubmit}
                      journeyStatus={journeyStatus}
                      saveUser={saveUser}
                      uuid={uuid}
                      response={response}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <Footer />
      </div>
    </>
  );
};

export default SPH_CDV3;
