import React from "react";
import Header from "./ACA_BQ_1/Header";
const Cookies_policy = ()=>{
    window.scrollTo(0, 0);
    return(
        <>
 <div className="cookies">       
<Header/>

  <div className="container pdf-section">
    <div className="row">
      <div className="col-12 inner">
          <div className="top-head text-center">
            <h1>
              <strong>Cookies Policy</strong>
            </h1>
          </div>

          <div className="left_date_time">
            <h2>Last Modified: 1 July 2023</h2>
          </div>

          <div className="points_title ">
            <h2>
              <strong>Introduction</strong>
            </h2>
          </div>

          <div>
            <p>Consejos de Veronica is committed to protecting your personal information and ensuring your experience with us is as safe and enjoyable as possible. This Cookies Policy should be read in conjunction with our Privacy Policy (which can be read here) and our Terms of Use (which can be read here). Our Cookies Policy explains how and why we use “cookies” within our website, software, or other services.</p>
          </div>

          <div className="points_title">
            <h2>
              <strong>What Are Cookies?</strong>
            </h2>
          </div>

          <div>
            <p>Cookies are small amounts of information in the form of text files sent by websites to computers, mobile phones, or other devices when you visit a website or use a mobile application. Although cookies do identify a user’s device, cookies do not personally identify users. Cookies allow companies to do various things, such as: tailoring content, personalizing website visits, and ensuring the security of any online experience. Cookies typically contain the name of the domain from which the cookie has come, the “lifetime” of the cookie, and a randomly generated unique number or other value. Cookies cannot be used to run programs or deliver viruses to your computer. Additionally, mobile devices may use other tracking files which are similar to cookies (for example iOS devices use Apple’s ‘identifier for advertisers’ (IDFA) and Android devices use Google’s Android ID). In the context of tracking within Resold, the concept of a cookie will include a mobile device identifier for the purpose of this Cookie Policy. Cookies may come in different formats. Session cookies are temporary cookies that remain in your browser until you leave a website. Persistent cookies remain in your browser for a longer period of time. Third-party cookies are cookies that are loaded onto your browser by third-parties. Third-party cookies are usually a type of persistent cookie and are stored until you delete them or they expire based on the time period set in each cookie.</p>
          </div>

          <div className="points_title">
            <h2>
              <strong>Our Use of Cookies</strong>
            </h2>
          </div>

          <div>
            <p>Consejos de Veronica uses cookies to distinguish you from other users, including, potentially, mobile device identities. This helps us to improve Consejos de Veronica’s website and provide you with the best user experience possible. Please be aware that cookies may be used on Consejos de Veronica’s website whether or not you have created an account or submitted information to us.</p>
            <p>We may use the following cookies:</p>
            <p>● Strictly Necessary Cookies. These are cookies that are required for the operation of Consejos de Veronica’s website, such as to enable you to log into secure areas and access different features.</p>
            <p>● Performance Cookies. These cookies recognize and count the number of Consejos de Veronica users and to track user navigation and Consejos de Veronica interaction/behavior. This information is used to improve the way Consejos de Veronica website function.</p>
            <p>● Functionality Cookies. These cookies recognize when you return to Consejos de Veronica’s website, enable personalized content, and recognize and remember your preferences.</p>
            <p>● Targeting Cookies. These cookies record your visit to Consejos de Veronica’s website, including the individual pages visited and the links followed.</p>
            <p>● Third-Party Cookies. Some of the persistent and session cookies used by Consejos de Veronica’s website may be set by us, and some are set by third parties who are delivering services on our behalf.</p>
            <p>Generally, the strictly necessary cookies and some performance and functionality cookies only last for the duration of your visit to a website or expire when you close out of Consejos de Veronica’s website: these are known as ‘session cookies’. The functionality cookies and some targeting and performance cookies will last for a longer period of time: these are known as ‘persistent cookies.’ We use session and persistent cookies for the following reasons:</p>
            <p>● Authentication, Personalization, and Security. Cookies help us verify your account and device and determine when you are logged in, so we can make it easier for you to access Consejos de Veronica’s website and to provide the appropriate experiences and features. We also use cookies to help prevent fraudulent use of login credentials and for security purposes.</p>
            <p>● Performance and Analytics. Cookies help us analyze how Consejos de Veronica’s website is being accessed and used and enable us to track performance of Consejos de Veronica’s website. For example, we use cookies to provide insights regarding Consejos de Veronica’s website performance, such as page views, conversion rates, device information, visitor IP addresses, and referral sites.</p>
          </div>

          <div className="points_title">
            <h2>
              <strong>Third-Party Cookies</strong>
            </h2>
          </div>

          <div>
            <p>When you use Consejos de Veronica’s website, you may also receive cookies from third parties. The information collected by such third-party cookies is governed by their privacy policies. We may use third-party cookies for the following reasons:</p>
            <p>● To allow our business partners to perform analytics and collect information and serve you advertisements.</p>
            <p>● To share information with third parties to gain insights and marketing information based on how you use Consejos de Veronica’s website.</p>
            <p>● To provide to social networks to better understand how users interact through the Internet.</p>
            <p>● To optimize Consejos de Veronica’s website.</p>
            <p>● To remember your user preferences and logins.</p>
          </div>

          <div className="points_title">
            <h2>
              <strong>Lawful Basis for Cookies Used</strong>
            </h2>
          </div>

          <div>
            <p>We use cookies to ensure that Consejos de Veronica’s website functions as intended and to provide you with any services that you may have requested. Additionally, we use cookies based on your consent, where required by law, as stated within the Privacy Policy.</p>
          </div>

          <div className="points_title">
            <h2>
              <strong>Opting out and Blocking Cookies</strong>
            </h2>
          </div>

          <div>
            <p>The cookies we use are designed to optimize your experience using Consejos de Veronica’s website. If you do not wish to receive cookies, most online browsers and custom device settings allow you to change your cookie settings. You can also learn more about cookies by visiting <a href="www.aboutcookies.org">www.aboutcookies.org</a> which includes additional useful information on cookies and how to block cookies using different types of browsers or devices. To block Android ID on your Android device, you should follow this path: Google Settings &gt; Ads and then turn on ‘Opt out of interest-based ads’. To block the IDFA on your iOS mobile device, you should follow this path: Settings &gt; General &gt; About &gt; Advertising and then turn on ‘Limit Ad Tracking’. Please note, however, that (as with other websites and apps) by blocking or deleting cookies used on Consejos de Veronica’s website you may not be able to take full advantage of Consejos de Veronica’s website. Opting out of any Internet-based advertising will only work for the browser you are currently using; any additional browsers or devices with which you access Consejos de Veronica’s website must be opted out of separately. Some opt-outs may only work if your browser accepts cookies. If you delete cookies, change your browser settings, switch browsers or computers, or use another device, you may be required to opt-out again. Please note that if you choose to refuse cookies, portions of Consejos de Veronica’s website may not function properly. For more information related to your cookie settings, please use the “Help” option located within your browser. Alternatively, you may wish to visit <a href="www.aboutcookies.org">www.aboutcookies.org</a>, which contains comprehensive information regarding the management of cookies on your browser.</p>
          </div>

          <div className="points_title">
            <h2>
              <strong>Internet Tracking</strong>
            </h2>
          </div>

          <div>
            <p>We may also use other online services to track your behavior, geolocation, and usage on Consejos de Veronica’s website. We use Google Analytics to help us measure traffic and usage trends for Consejos de Veronica’s website. Additionally, Google Analytics helps us understand more about the demographics of our users. You can learn more about Google’s practices at <a href="https://policies.google.com/privacy/partners">https://policies.google.com/privacy/partners</a>, and you may opt-out of Google Analytics by visiting <a href=" https://tools.google.com/dlpage/gaoptout"> https://tools.google.com/dlpage/gaoptout</a>.</p>
          </div>

          <div className="copy-ryt pt-3">
            <p> 
              <b>Copyright 2022. All rights reserved.</b>
            </p>
          </div>

      </div>
    </div>
</div>

</div>
        </>
    )
}
export default Cookies_policy