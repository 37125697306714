import { Api } from "../api/Api";
// import { useErrorWebhook } from "./useErrorWebhook";

export const useRingbaPhoneSave = () => {
  // const { ErrorWebhookTrigger } = useErrorWebhook();
  const saveRingbaphone = async (uuid, first_name, phone_number, state, status, ringba_response) => {
    const response = await Api.post("v1/ringba-phone-save", {
      uuid,
      first_name,
      phone_number,
      state,
      status,
      ringba_response
    }).catch(function (error) {
      const erroMessage = {
        serviceName: 'RINGBA PHONE SAVE',
        errorReason: error.message,
        errorCode: error.code,
        uuid: uuid
      }
      // ErrorWebhookTrigger(erroMessage);
    });
    return response;
  };
  return { saveRingbaphone };
}
