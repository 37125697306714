import { useHistory } from "react-router-dom";
import { UUID } from "./UUID";
import { urlParams } from "../Utility/QueryString";

export const CheckUUID = () => {
  const full_url = window.location.href;
  const params = Object.fromEntries(urlParams.entries());
  const query = new URLSearchParams(window.location.search);
  const history = useHistory();
  const { fetchUuid } = UUID();
  const split_name = query.get("splitName");
  const dob = query.get("dob");
  const isCheck = () => {
    var flag = false;
    var localFlag = false;
    if (!params.hasOwnProperty("uuid") || (params.hasOwnProperty("uuid") && !urlParams.get("uuid")) || (params.hasOwnProperty("uuid") && urlParams.get("uuid") === '""')) {
      flag = true;
    }
    if (!localStorage.getItem('uuid')) {
      localFlag = true;
    }
    if (flag && localFlag) {
      history.push("/404");
      return false;
    } else {
      const uuid = fetchUuid();
      window.history.pushState({}, '', '?uuid=' + uuid + '&splitName=' + split_name );
      return true;
    }
  }
  return { isCheck }
}