import React from 'react'
import { Link } from "react-router-dom";

const Footer = () => {
    return (
        <>
            {/* =========FOOTER-SECTION ===========*/}
            <footer className="py-4 text-center">
                <div className="container">
                    <div className="offset-lg-1 col-lg-10">
                        <div className="row mb-3">
                            <div className="py-2">
                                <p>
                                No estamos afiliados ni respaldados por ninguna entidad gubernamental. CMS no ha revisado ni aprobado esta información. Esta no es una descripción completa de los beneficios, visite {" "}
                                    <a
                                        className="foot"
                                        target="_blank"
                                        href="https://www.healthcare.gov/"
                                    >
                                        healthcare.gov
                                    </a>{" "} para obtener una lista completa de planes y beneficios disponibles. Los beneficios pueden estar limitados a planes y regiones específicas. Los beneficios enumerados se ofrecen como parte de algunos Planes de Salud Seguro para Latinos y Planes de Medicare Advantage, la inscripción reemplazará su cobertura de salud actual. No hay obligación de inscribirse.
                                </p>

                            </div>
                            <div className=" py-2">
                                <p>
                                *No todos los planes o beneficios están disponibles en todas las áreas. Este beneficio solo está disponible para solicitantes elegibles en los siguientes estados: AL, AZ, FL, GA, MS, NC, SC, TX, UT, VA. No todos los que llaman de estos estados calificarán para el beneficio indicado.
                                </p>
                            </div>
                              {/* <div>
                                <p>Javier Eduardo Polo</p>
                              </div> */}
                            <div className="link_secc">
                          
                                
                                    *<a  className="foot"
                                        target="_blank"
                                        href="https://www.cbo.gov/publication/59273"
                                    >
                                     CBO.GOV
                                    </a>
                                   
                            </div>
                            <div className="link_secc">
                           
                                    *<a  className="foot"
                                        target="_blank"
                                        href="https://www.kff.org/health-reform/state-indicator/average-monthly-advance-premium-tax-credit-aptc/?currentTimeframe=0&sortModel=%7B%22colId%22:%22Location%22,%22sort%22:%22asc%22%7D"
                                    >
                                    KFF.ORG 
                                    </a>
                                   
                            </div>      

                                
                            <div className="link_sec">
                                <p>
                                    © 2013-2022 |{" "}

                                    <Link target="_blank" to="Privacy-policy">Política de Privacidad</Link>
                                    |{" "}
                                    <Link target="_blank" to="Terms-of-use">Términos de Uso</Link>
                                    |
                                    {" "}
                                    <Link target="_blank" to="Cookies-policy" >Política de Cookies</Link>
                                    {" "}
                                   
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
        </>
    )
}

export default Footer