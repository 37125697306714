import React from 'react';

const Partners_list = () => {
  return (
    <>
      <div className='partners_list'>
        <div className='container-fluid'>
          <div className='container'>
            <h5>
              <span> Nuestros socios comerciales </span>
            </h5>
            <ul>
              <li>AAA ACG</li>
              <li>AAA Life (Converge) </li>
              <li>AAA Life - Traditional Term </li>
              <li>AA Health Advisors </li>
              <li>AA Media Inc </li>
              <li>AB Financial Group </li>
              <li>ABE House Media </li>
              <li>Acceptance Insurance </li>
              <li>Accuquote </li>
              <li>ACE Agents </li>
              <li>Ace Health Solutions </li>
              <li>ACE Solutions </li>
              <li>AdMediary </li>
              <li>Advance Client Solutions </li>
              <li>Advanced Health Group </li>
              <li>Advanced Health Insurance Agency </li>
              <li>Aetna </li>
              <li>Agency Incline </li>
              <li>AgileRates </li>
              <li>AIG Direct </li>
              <li>AIS </li>
              <li>Alfa </li>
              <li>Alliance </li>
              <li>Allied Coverage </li>
              <li>Allstate </li>
              <li>All Web Leads </li>
              <li>ALM Health Group </li>
              <li>Alpine Digital Group, Inc </li>
              <li>Alternative Health Solutions LLC </li>
              <li>American Central Marketing Group LLC </li>
              <li>American Dream Health Solutions (Secure Health) </li>
              <li>American Family </li>
              <li>American Health Way </li>
              <li>American Income Life Insurance Company </li>
              <li>American National </li>
              <li>American Select Health </li>
              <li>American Way Health </li>
              <li>America's Health Advisors </li>
              <li>Americas Health Agents LLC </li>
              <li>America's Trust, Inc. </li>
              <li>Americo Life </li>
              <li>Ameriquote </li>
              <li>Anthem </li>
              <li>AnthemBCBS-Medicare Advantage </li>
              <li>Anthem Health (Assembly) </li>
              <li>Anthem Medicare Advantage (Assembly) </li>
              <li>Apex Health Options </li>
              <li>AP Insurance Advisors </li>
              <li>Apollo Interactive </li>
              <li>Assurance IQ, Inc </li>
              <li>Assure Media Inc </li>
              <li>Atlantis Health Group Inc </li>
              <li>Avenge Digital </li>
              <li>Axad Capital </li>
              <li>Ax Health Insurance </li>
              <li>Axis Health Calvary </li>
              <li>A4D LLC</li>
              <li>Bankers Life </li>
              <li>Bankrate </li>
              <li>Bennett Insurance Agency </li>
              <li>Best Auto Insurance </li>
              <li>Best life insurance plans and rates | LifePlans.com </li>
              <li>Better Health Consulting </li>
              <li>Beyond Health Solutions </li>
              <li>Beyond Health Solutions LLC </li>
              <li>Blue Cross </li>
              <li>BlueCross BlueShield of South Carolina (Catalyst Media Design) </li>
              <li>Blue Shield of California </li>
              <li>Blue Sky Coverage </li>
              <li>Blueslate Insurance Agency LLC </li>
              <li>Blue Wing Ads </li>
              <li>Boomer Media Group </li>
              <li>Boundless Rider </li>
              <li>Branch Insurance </li>
              <li>Broadbase Media </li>
              <li>Bubble Insurance </li>
              <li>Care Source Group </li>
              <li>Carida Insurance Services </li>
              <li>Car Insurance </li>
              <li>Central Health Advisors </li>
              <li>Champion Health Brokers </li>
              <li>Cigna </li>
              <li>ClearMatch Medicare </li>
              <li>Coastal Marketing Advisors </li>
              <li>Colari Health </li>
              <li>ComfortCare Insurance Group </li>
              <li>Commonwealth </li>
              <li>Compare Health Insurance Quotes Online | FirstQuote Health </li>
              <li>Compare Medicare Advantage Plans | MedicareAdvantage.com </li>
              <li>Comparion </li>
              <li>Connie Health </li>
              <li>Country Financial </li>
              <li>Coverage Cure, Inc. </li>
              <li>Coverage One Insurance </li>
              <li>Dairyland Insurance </li>
              <li>Datalot </li>
              <li>Devoted Health </li>
              <li>Digital Agents United </li>
              <li>Digital Market Media </li>
              <li>Digital Media Solutions </li>
              <li>Direct Auto </li>
              <li>Direct Health Solutions </li>
              <li>Direct Response Leads </li>
              <li>Diverse Marketing </li>
              <li>DMS Insurance </li>
              <li>Doc Auto Insurance </li>
              <li>Dozer Health </li>
              <li>DriveMP</li>
              <li>Dynamic Insurance Services </li>
              <li>EasyHealth Insurance Services </li>
              <li>easyMedicare , an affiliate of e-TeleQuote Insurance, Inc </li>
              <li>EDM Leads </li>
              <li>Efinancial </li>
              <li>eHealth Insurance </li>
              <li>Elephant </li>
              <li>Elite Care Direct </li>
              <li>Elite Health Agency </li>
              <li>Empire Health Consultants </li>
              <li>Erie Insurance Company </li>
              <li>Essential Health Solutions </li>
              <li>Esurance </li>
              <li>ETHOS </li>
              <li>Ethos Life </li>
              <li>Even Financial </li>
              <li>Everquote </li>
              <li>Evolved Insurance Agency </li>
              <li>Excel Impact </li>
              <li>Excellus Blue Cross Blue Shield </li>
              <li>Exclusive Insurance Partners </li>
              <li>Experian </li>
              <li>Fabric </li>
              <li>Family First Insurance Advisors LLC </li>
              <li>Family Health First </li>
              <li>Family Heritage Life Insurance Company of America </li>
              <li>Farber Health Advisors </li>
              <li>Farm Bureau </li>
              <li>Farmers </li>
              <li>Farmers Insurance </li>
              <li>FFL </li>
              <li>Fidelity Investments Life </li>
              <li>Fidelity Life </li>
              <li>Final Expense Quotes </li>
              <li>Find Affordable Health Insurance Plans | Get Me Healthcare | 866-611-0519 </li>
              <li>Fiorella Insurance </li>
              <li>Fiorella Insurance Agency </li>
              <li>First Family Insurance </li>
              <li>First Health Alliance </li>
              <li>Florida Blue </li>
              <li>Fortegra Insurance </li>
              <li>Fuego Leads </li>
              <li>Gainsco </li>
              <li>Garland Financial </li>
              <li>GEICO </li>
              <li>Gerber </li>
              <li>GetaQuote.com </li>
              <li>Glassroots Marketing </li>
              <li>Global Health and Life </li>
              <li>Globe Life </li>
              <li>Globe Life Insurance Company </li>
              <li>GoHealth </li>
              <li>Gold Standard Health LLC </li>
              <li>GoMedicare </li>
              <li>GR Consulting Services LLC</li>
              <li>goMedigap </li>
              <li>Goosehead </li>
              <li>Great Life Insurance </li>
              <li>Guidestar Marketing Group LLC </li>
              <li>Hamburger Healther </li>
              <li>Hardill Marketing </li>
              <li>Harley-Davidson Insurance </li>
              <li>Health Benefits Center </li>
              <li>Healthcare Solutions Team - HST </li>
              <li>HealthCompare </li>
              <li>Health Connect Insurance Agency </li>
              <li>Health First Insurance Agency </li>
              <li>HealthInsurance.net | Get a Quote </li>
              <li>Health Insurance of America </li>
              <li>Health Insurance Pro's </li>
              <li>Health IQ Insurance Agency </li>
              <li>HealthMarkets - Direct </li>
              <li>Health Markets (Horizon) </li>
              <li>HealthMarkets Insurance Agency </li>
              <li>HealthMatchup </li>
              <li>HealthPlanOne, LLC </li>
              <li>Health Team One </li>
              <li>Heritage Health Advisors </li>
              <li>Heritage Life Insurance Company </li>
              <li>HIEG Partners, LLC </li>
              <li>Hippo Insurance </li>
              <li>HiRoad - AZ </li>
              <li>Home </li>
              <li>Hometown Quotes </li>
              <li>Honeycomb Insurance </li>
              <li>Hoot Hoot Health, LLC </li>
              <li>Horizon Health Advisors (DH) </li>
              <li>H&amp;R Marketing </li>
              <li>HugeDomains.com </li>
              <li>Ideal Concepts </li>
              <li>Ideal Concepts, Inc </li>
              <li>IHC Group </li>
              <li>Imperium Benefits </li>
              <li>Imperium Financials </li>
              <li>InboundPro </li>
              <li>Independent Agent </li>
              <li>Indy </li>
              <li>Infinity Care Health </li>
              <li>Infinity Insurance Advisors </li>
              <li>Infinix </li>
              <li>Inside Response </li>
              <li>InsuranceHoney - Sweet deals on Insurance </li>
              <li>Insurance Line One </li>
              <li>
                InsuranceRatesForLess | Auto Insurance | Home Insurance | Let's Drop Your Rate{' '}
              </li>
              <li>Insurance XL, Inc</li>
              <li>Insured Nation </li>
              <li>InsureMe, Inc </li>
              <li>InsureZella </li>
              <li>Insurify </li>
              <li>Insurita </li>
              <li>Integriant Ventures Insurance Services </li>
              <li>Integrity Health One </li>
              <li>iRadiusGroup </li>
              <li>iWebQuotes </li>
              <li>JA Insurance</li>
              <li>Jerry </li>
              <li>Jessica Ostler Insurance </li>
              <li>JM Health Associates </li>
              <li>John Douglas Insurance </li>
              <li>Justified Medical Group </li>
              <li>Just Insure </li>
              <li>Kaiser Permanente </li>
              <li>Kemper </li>
              <li>KERWYN JONES </li>
              <li>KH Advisors </li>
              <li>Kin Insurance </li>
              <li>Kirk Hilt Insurance </li>
              <li>Ladder Life </li>
              <li>Las Madrinas</li>
              <li>Leading Healthcare Solutions </li>
              <li>Legacy Insurance Advisors LLC </li>
              <li>Legal &amp; General America </li>
              <li>Lemonade </li>
              <li>Leosource Insurance Agency </li>
              <li>Liberty Mutual </li>
              <li>Liberty National Life Insurance Company </li>
              <li>Liberty Wellness </li>
              <li>LifeInsuranceMatchup </li>
              <li>LifeInsurancePro </li>
              <li>Lighthouse Insurance </li>
              <li>Liv Better Health LLC </li>
              <li>Longspur Health LLC </li>
              <li>LOOP </li>
              <li>LSC Advisors </li>
              <li>Madera Digital </li>
              <li>MAPFRE Insurance Company </li>
              <li>Marble </li>
              <li>Marketcall</li>
              <li>Mass Mutual </li>
              <li>MediaAlpha </li>
              <li>Medicare Advisors 365 </li>
              <li>Medicare Concepts </li>
              <li>MedicareEnroller.com </li>
              <li>Medicare Helpline </li>
              <li>Medicare Solutions </li>
              <li>Medicare Supplemental Insurance </li>
              <li>Medigap </li>
              <li>Mercury Insurance </li>
              <li>MetLife </li>
              <li>Mile Auto </li>
              <li>Millennial Health </li>
              <li>Molina Healthcare </li>
              <li>Monumental Health LLC </li>
              <li>MSR Health Insurance Agency </li>
              <li>Mutual of Omaha </li>
              <li>MVX Sales </li>
              <li>Mydas </li>
              <li>My Health Angel </li>
              <li>My Plan Avocate </li>
              <li>My Trusted Quotes </li>
              <li>National General </li>
              <li>National Health Plans </li>
              <li>National Home Quotes </li>
              <li>National Income Life Insurance Company </li>
              <li>Nationwide </li>
              <li>Nationwide Insurance Company </li>
              <li>Navy Mutual </li>
              <li>Nectar Marketplace - Insurance Agents and Agencies </li>
              <li>Need Health </li>
              <li>NerdInsure </li>
              <li>Nest Insurance Agency LLC </li>
              <li>New Age Health </li>
              <li>New York Life AARP </li>
              <li>New York Life Insurance Group </li>
              <li>New York Life (Southern California General Office - S75) </li>
              <li>NextGen Leads </li>
              <li>NOBLR </li>
              <li>Northeastern Health Group </li>
              <li>Novo </li>
              <li>Nsure </li>
              <li>Nu Life Health </li>
              <li>Nxt Level Health </li>
              <li>Oak Hill Insurance </li>
              <li>Oak Street Health </li>
              <li>Ocean Insurance Agency </li>
              <li>Odyssey Insurance Agency </li>
              <li>OfficialCarInsurance </li>
              <li>OnStar </li>
              <li>Optimum Health Options </li>
              <li>Optum Holdings, LLC </li>
              <li>Paramount Health LLC </li>
              <li>PathIQ </li>
              <li>PEMCO Insurance </li>
              <li>Pet Insurance Market </li>
              <li>Platinum Health Advisors </li>
              <li>Plymouth Rock </li>
              <li>Policy Fuel LLC </li>
              <li>Precision Health Associates </li>
              <li>Precursor Media </li>
              <li>Precursor Media LLC </li>
              <li>Premier Health Association </li>
              <li>Presidio Interactive </li>
              <li>Pretected </li>
              <li>Prime Health Insurance Agency </li>
              <li>Primerica </li>
              <li>Priority Health One LLC </li>
              <li>Prodigy Insurance Association </li>
              <li>Progressive </li>
              <li>Progressive Insurance </li>
              <li>Protect Auto Insurance </li>
              <li>Provide Insurance </li>
              <li>Pru Advisors - Mike Goldstein </li>
              <li>Prudential of America </li>
              <li>PX </li>
              <li>Quantum Digital Media, Inc</li>
              <li>Quantum 3 Media </li>
              <li>Quantum Insurance Solutions </li>
              <li>Quotelab </li>
              <li>QuoteLab, LLC </li>
              <li>QuotesMatch </li>
              <li>Quote Today </li>
              <li>Quote Velocity </li>
              <li>QuoteWizard </li>
              <li>Rank Media Agency </li>
              <li>RateQuote </li>
              <li>Regal Insurance Agency LLC </li>
              <li>RevPoint Media </li>
              <li>RevriseMedia </li>
              <li>Rio Technologies Ltd </li>
              <li>RKW Directions </li>
              <li>Rocket Health </li>
              <li>Root Insurance </li>
              <li>Savvy </li>
              <li>S.B. Fintech Ltd </li>
              <li>SCAN Health Plan </li>
              <li>Secure Health Enterprises </li>
              <li>SelectQuote </li>
              <li>Senior Life Agency </li>
              <li>Serenity Insurance </li>
              <li>Shelter </li>
              <li>Shelter Insurance Company </li>
              <li>Sicuro Health LLC </li>
              <li>SmartFinancial Insurance </li>
              <li>Smart Match </li>
              <li>S&amp;M Marketing Associates LLC </li>
              <li>SolidQuote, LLC </li>
              <li>SPI Health Holdings </li>
              <li>Spring Health Plans </li>
              <li>Spring Insurance Solutions </li>
              <li>Spring Venture Group </li>
              <li>
                Squeeze | Find the lowest rates on your recurring monthly bills. Not just once, but
                forever.{' '}
              </li>
              <li>State Farm </li>
              <li>Stoic Insurance Group </li>
              <li>SureInt </li>
              <li>Synergy Insurance</li>
              <li>Synergy Insurance Affiliates </li>
              <li>Taylored Legacy </li>
              <li>Ted Todd Insurance Agency </li>
              <li>The Commerce Insurance Company </li>
              <li>The General </li>
              <li>The Rindal Agency </li>
              <li>The Zebra </li>
              <li>Tiburon Insurance Services </li>
              <li>Titan Coverage </li>
              <li>
                Tobias &amp; Associates ( Find Affordable Health Insurance Plans | Get Me Healthcare
                | 866-611-0519 ){' '}
              </li>
              <li>Top Healthcare Direct </li>
              <li>Top Healthcare Options, LLC </li>
              <li>Top Healthcare Solutions </li>
              <li>Total Insurance Solutions </li>
              <li>Transamerica </li>
              <li>Tranzact </li>
              <li>Travelers </li>
              <li>Triumphant Health </li>
              <li>True Source Insurance Agency </li>
              <li>TruSource Mktg </li>
              <li>TruStage </li>
              <li>TrustedConsumer LLC </li>
              <li>Trusted Healthcare Providers </li>
              <li>TZ Insurance Solutions LLC </li>
              <li>UHC/AARP Medicare Advantage (Direct) </li>
              <li>UHC/AARP Medicare Advantage - D-SNP (Direct) </li>
              <li>UHC/AARP Medicare Supplement (Direct) </li>
              <li>UHC - ACA (Direct) </li>
              <li>UMA </li>
              <li>Underground Elephant </li>
              <li>Unik Media Group</li>
              <li>United Advisors LLC </li>
              <li>United American Insurance Company </li>
              <li>United Health </li>
              <li>United Healthcare Advisors </li>
              <li>United Insurance </li>
              <li>United Medicare Advisors </li>
              <li>United States Insurance - Compare Free United States Insurance Rates </li>
              <li>Univera Healthcare </li>
              <li>Universal Health Advisors </li>
              <li>Universal Health Care </li>
              <li>Universal Healthcare Advisors </li>
              <li>USAA </li>
              <li>USA Underwriters </li>
              <li>USHealth Advisors </li>
              <li>US Standard Products </li>
              <li>Veerus Leads </li>
              <li>Velapoint </li>
              <li>Veterans United Home Loans </li>
              <li>Visiqua </li>
              <li>Visiqua LLC </li>
              <li>Vitalone Health </li>
              <li>Vital Pro Health </li>
              <li>VIU by HUB International </li>
              <li>Voom Insurance </li>
              <li>Waffle Labs, Inc </li>
              <li>Walmart Health </li>
              <li>WeCall Media</li>
              <li>Worldwide United Healthcare LLC </li>
              <li>Wysh Life and Health Insurance Company </li>
              <li>YB Essential Health </li>
              <li>YourMedicareMatch </li>
              <li>Zurich North America or other partners </li>
            </ul>
          </div>
        </div>
      </div>
    </>
  );
};

export default Partners_list;
