import React, { useState, useEffect } from 'react';
import { RingbaCheckUUID } from '../../Utility/RingbaCheckUUID';
import { Helmet } from 'react-helmet';
import { GreencardPhoneNumberConstants } from '../../Constants/PhoneNumberConstants';
import "../../assets/css/greencard-medicare-ty.scss";
import Time from '../Includes/Layouts/SPH_CDVS31/Time';
import { useUserClicks } from '../../Hooks/useUserClicks';
import useJourneyStats from "../../Hooks/useJourneyStats";

const GreencardThankyou = () => {
    const { isCheck, } = RingbaCheckUUID();
    isCheck();
    const query = new URLSearchParams(window.location.search);
    const utmName = (query.get('utm_campaign')) ? query.get('utm_campaign') : 'default';
    const [formattedNumber, setFormattedPhoneNumber] = useState('');
    const utm_phonenumber = (GreencardPhoneNumberConstants[utmName]) ? GreencardPhoneNumberConstants[utmName] : GreencardPhoneNumberConstants['default'];
    const [phoneNumber, setPhoneNumber] = useState(utm_phonenumber);
    const uuid = query.get('uuid');
    const saveJournyStats = useJourneyStats();

    const { saveUserClicks } = useUserClicks();
    const saveUser = async (name, status) => {
        await saveUserClicks(uuid, name, status);
    };

    const splitName = localStorage.getItem('split_name');
    useEffect(() => {
        (async () => {
            await saveJournyStats(
                uuid,
                "BUTTON",
                "GREENCARD_CLICK",
                "",
                "view",
                splitName
            );
        })();
    }, []);

    const saveCallClick =()=>{
        saveUser('GREENCARD_CLICK', 1);
        (async () => {
            await saveJournyStats(
              uuid,
              "BUTTON",
              "GREENCARD_CLICK",
              "",
      
              "click",
              splitName
            );
          })();
    }
    
    useEffect(() => {
        if (phoneNumber) {
            const digits = phoneNumber.replace(/\D/g, '').slice(-10);
            if (digits.length === 10) {
                const formattedNumber = digits.replace(/(\d{3})(\d{3})(\d{4})/, '$1-$2-$3');
                setFormattedPhoneNumber(formattedNumber);
            }
        }
    }, [phoneNumber]);

    return (
        <>
            <Helmet>
                <script src="//b-js.ringba.com/CAee7b2b5cb2f6405ca2e4500f2c8e8e0b" async></script>
            </Helmet>
            <div className="greencard-medicare-ty">
                <div className="popup-wrap">
                    <div className="popup">
                        <div className="text-popup">
                            <h5>
                                <b>¡Felicidades! 🤩🤩🥳🥳🥳</b>
                            </h5>
                            <h6>
                                ¡Calificas para una llamada de asesoramiento gratuita para conocer tus opciones y poder obtener un estatus migratorio legal!
                            </h6>
                            <h6>
                                Si te gusta lo que escuchas, este socio cuidadosamente seleccionado ofrece sus servicios a precios mucho más bajos que los que obtendrías de un bufete de abogados y estará contigo en cada paso del camino para cambiar tu vida.
                            </h6>
                            <h6>
                                Mantendremos la línea abierta durante 60 segundos.
                            </h6>
                            <h6>
                                1 agente disponible Cuenta regresiva desde <span className='text-danger'><Time minuteLoad="0" timeLoad={true} /></span>
                            </h6>
                        </div>
                        <div className="btn-div">
                            <a href={`tel: ${phoneNumber}`} onClick={saveCallClick}>
                                <button className="anime-pulse">
                                    {formattedNumber}
                                </button>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default GreencardThankyou;
