import { Api } from "../api/Api";

export const useRingbaInfo = () => {
  const postRingbaInfo = async (campaign_name) => {
    const response = await Api.post("v1/ringba-online", {
      "campaign_name":campaign_name
    });
    return response;
  };

  const postRingbaDob = async (values) => {
    const response = await Api.post("v1/ringba-dob-save", values); // Pass requestData directly
    return response;
  };

  return { postRingbaInfo, postRingbaDob };

};
