import React from 'react';
import { DobYearRingba, DobMonthRingba, DobDayRingba } from '../../Constants/Constants';
import SelectBox from '../UI/SelectBox';


const DateOfBirth = ({ textDob, validation, validationMsg,clearErrors }) => {

    return (
        <div className="form-group col-12">
            <fieldset className="scheduler-border">
                <legend className="scheduler-border">{textDob}</legend>
                <div className="row">
                    <div className="col-lg-4 col-md-4 col-sm-4 col-xs-12 mb-3">
                        <div className="form-group">
                            <SelectBox
                                className="form-control"
                                OptionValue={DobDayRingba}
                                onChange={() => clearErrors("DobDay")}
                                name="DobDay"
                                id="DobDay"
                                myRef={validation({
                                    required: "por favor seleccione el día"
                                })}
                                // validationMsg={validationMsg.DobDay && validationMsg.DobDay.message}
                            />
                            <span style={{
                                color: 'red', fontSize: "10px",
                                fontWeight: "600"
                            }}>{validationMsg.DobDay && validationMsg.DobDay.message}</span>
                            <i className="tick fa" style={{ display: 'none' }}></i>
                            {/* <span id="dobDay_err" className="error_msg"></span> */}
                        </div>
                        
                    </div>
                    <div className="col-lg-4 col-md-4 col-sm-4 col-xs-12 mb-3">
                        <div className="form-group">
                            <SelectBox
                                className="form-control"
                                OptionValue={DobMonthRingba}
                                onChange={() => clearErrors("DobMonth")}
                                name="DobMonth"
                                id="DobMonth"
                                myRef={validation({
                                    required: "Por favor seleccione mes"
                                })}
                                // validationMsg={validationMsg.DobMonth && validationMsg.DobMonth.message}
                            />
                            <span style={{
                                color: 'red', fontSize: "10px",
                                fontWeight: "600"
                            }}>{validationMsg.DobMonth && validationMsg.DobMonth.message}</span>
                            <i className="tick fa" style={{ display: 'none' }}></i>
                            {/* <span id="dobMonth_err" className="error_msg"></span> */}
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-4 col-sm-4 col-xs-12 mb-3">
                        <div className="form-group">
                            <SelectBox
                                className="form-control"
                                OptionValue={DobYearRingba}
                                onChange={() => clearErrors("DobYear")}
                                name="DobYear"
                                id="DobYear"
                                myRef={validation({
                                    required: "Por favor seleccione año "
                                })}
                                // validationMsg={validationMsg.DobYear && validationMsg.DobYear.message}
                            />
                            <span style={{
                                color: 'red', fontSize: "10px",
                                fontWeight: "600"
                            }}>{validationMsg.DobYear && validationMsg.DobYear.message}</span>
                            <i className="tick fa" style={{ display: 'none' }}></i>
                            {/* <span id="dobYear_err" className="error_msg"></span> */}
                        </div>
                    </div>
                    <span id="dob_final_err" className="error_msg"></span>
                </div>
            </fieldset>
        </div>
    )
}

const JointDateOfBirth = ({ textDob, validation, validationMsg }) => {

    return (
        <div className="form-group col-12">
            <fieldset className="scheduler-border">
                <legend className="scheduler-border">{textDob}</legend>
                <div className="row">
                    <div className="col-lg-4 col-md-4 col-sm-4 col-xs-12">
                        <div className="form-group">
                            <SelectBox
                                className="form-control"
                                OptionValue={DobDayRingba}
                                name="JointDobDay"
                                myRef={validation({
                                    required: "por favor seleccione el día"
                                })}
                                validationMsg={validationMsg.JointDobDay && validationMsg.JointDobDay.message}
                            />
                            <i className="tick fa" style={{ display: 'none' }}></i>
                            {/* <span id="dobDay_err" className="error_msg"></span> */}
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-4 col-sm-4 col-xs-12">
                        <div className="form-group">
                            <SelectBox
                                className="form-control"
                                OptionValue={DobMonthRingba}
                                name="JointDobMonth"
                                myRef={validation({
                                    required: "Por favor seleccione mes"
                                })}
                                validationMsg={validationMsg.JointDobMonth && validationMsg.JointDobMonth.message}
                            />
                            <i className="tick fa" style={{ display: 'none' }}></i>
                            {/* <span id="dobMonth_err" className="error_msg"></span> */}
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-4 col-sm-4 col-xs-12">
                        <div className="form-group">
                            <SelectBox
                                className="form-control"
                                OptionValue={DobYearRingba}
                                name="JointDobYear"
                                myRef={validation({
                                    required: "Por favor seleccione año"
                                })}
                                validationMsg={validationMsg.JointDobYear && validationMsg.JointDobYear.message}
                            />
                            <i className="tick fa" style={{ display: 'none' }}></i>
                            {/* <span id="dobYear_err" className="error_msg"></span> */}
                        </div>
                    </div>
                    <span id="dob_final_err" className="error_msg v4_dobfinal"></span>
                </div>
            </fieldset>
        </div>
    )
}

export { DateOfBirth, JointDateOfBirth };