export const PhoneNumberConstants = {
    "default": "+18775360756",
    "ACA_V1_FB_JAMES": "+18775360756",
    "ACA_US_ESS1_META_FB_ART": "+18553940298",
    "ACA_US_ESSM_META_FB_GEOFF": "+18556730704",
    "default_SPH": "+18335063463",
    "ACA_US_ESP_FB_Art":"+18884140368",
    "ACA_US_ESP_FB_Geoff":"+18884141283",
    "ACA_US_ESP_FB_ART":"+18884140368",
    "ACA_US_ESP_FB_GEOFF":"+18884141283",
    "DEV_TEST_ACA_ESP_ART":"+18884140368",
    "DEV_TEST_ACA_ESP_GEOFF":"+18884141283",
    "PRE_TEST_ACA_ESP_ART":"+18884140368",
    "PRE_TEST_ACA_ESP_GEOFF":"+18884141283",
    "ACA_ENG_FB_CB_VAN":"+18556151793",
    "ACA_ESP_FB_CB_VAN":"+18556151799",
    "ACA_ENG_FB_META_ANTONIO":"+18444551366",
    "ACA_ESP_GEO_META_ART":"+18884140368",
    "ACA_VERONICA_ESP_ART":"+18884140368",
    "ACA_Veronica_YT_ESP_Art":"+18884140368",
    "ACA_VERONICA_YT_ESP_ART":"+18884140368",
    "ACA_Veronica_ESP_Geo_Meta_Art":"+18884140368",
    "ACA_VERONICA_ESP_GEO_META_ART":"+18884140368",
    "ACA_Veronica_ESP_META_JMS":"+18775080158",
    "ACA_VERONICA_ESP_META_JMS":"+18775080158",
    "ACA_VERONICA_ESP_FB_RAUL":"+18333162345",
    "ACA_VERONICA_ESP_FB_BRIANNE":"+18333340595",
    "ACA_VERONICA_ESP_TIKTOK_BRIANNE":"+18333340595",
    "ACA_VERONICA_ESP_FB_B1_ADAM":"+18665977183",
    "ACA_VERONICA_ESP_LPC_JMS":"+18775080158",
    "ACA_VERONICA_ESP_FB_B1_SAMUEL":"+18775630671",
    "ACA_J_CDV_LEAD_GEN_FB_ART":"+18884140368",
    "ACA_B_CDV_LEAD_GEN_FB_SAM":"+18775630671",
    "ACA_B_CDV_LEAD_GEN_FB_ADAM":"+18665977183",
    "ACA_J3_CDV_LEAD_GEN_FB_JMS":"+18775080158",
    "ACA_CDV_JV3_FB_ADAM":"+18665977183",
    "ACA_JV3_CDV_LEAD_GEN_FB_ADAM":"+18665977183",
    "ACA_VERONICA_ESP_FB_GW_VAF":"+18775081671",
    "ACA_VERONICA_ESP_FB_GW_ART":"+18884140368",
    "ACA_GW_CDV_LEAD_GEN_FB_VAF":"+18775081671",
    "ACA_J3_CDV_LEAD_GEN_TAB_JMS":"+18775080158",
    "ACA_CDV_ESP_LEAD_VAN_SAM_FB" : "+18775630671",
    "ACA_CDV_ESP_VAN_SAM_FB" : "+18775630671",
    "ACA_CDV_ESP_LEAD_AD_VAF_FB":"+18775081671",
    "ACA_CDV_ESP_AD_VAF_FB":"+18775081671",
    "ACA_CDV_LEAD_BLINKNEO_SAM_FB":"+18775630671",
    "ACA_CDV_ESP_BLINKNEO_SAM_FB":"+18775630671",
    "ACA_CDV_LEAD_BLINKNEO_SAM_CC2_FB":"+18775630671",
    "ACA_CDV_BLINKNEO_SAM_CC2_FB":"+18775630671"
};

export const GreencardPhoneNumberConstants = {
    "default": "+18885380531",
    "ACA_VERONICA_ESP_FB_B1_SAMUEL" : "+18554973175",
    "ACA_VERONICA_ESP_FB_B1_ADAM" : "+18446471346",
    "ACA_VERONICA_ESP_META_JMS" : "+18557141034",
    "ACA_VERONICA_ESP_LPC_JMS" : "+18557141034",
    "ACA_VERONICA_ESP_GEO_META_ART" : "+18446530324",
    "ACA_VERONICA_YT_ESP_ART" : "+18446530324",
    "ACA_VERONICA_ESP_ART" : "+18446530324",
    "ACA_J_CDV_LEAD_GEN_FB_ART" : "+18446530324",
    "ACA_B_CDV_LEAD_GEN_FB_SAM": "+18554973175",
    "ACA_B_CDV_LEAD_GEN_FB_ADAM": "+18446471346",
    "ACA_J3_CDV_LEAD_GEN_FB_JMS": "+18557141034",
    "ACA_VERONICA_ESP_FB_GW_VAF": "+18447191967",
    "ACA_VERONICA_ESP_FB_GW_ART": "+18446530324",
    "ACA_GW_CDV_LEAD_GEN_FB_VAF": "+18447191967",
    "ACA_J3_CDV_LEAD_GEN_TAB_JMS": "+18557141034",
    "ACA_CDV_ESP_LEAD_VAN_SAM_FB": "+18554973175",
    "ACA_CDV_ESP_VAN_SAM_FB": "+18554973175",
    "ACA_CDV_ESP_LEAD_AD_VAF_FB":"+18447191967",
    "ACA_CDV_ESP_AD_VAF_FB":"+18447191967",
    "ACA_CDV_LEAD_BLINKNEO_SAM_FB":"+18554973175",
    "ACA_CDV_ESP_BLINKNEO_SAM_FB":"+18554973175",
    "ACA_CDV_LEAD_BLINKNEO_SAM_CC2_FB":"+18554973175",
    "ACA_CDV_BLINKNEO_SAM_CC2_FB":"+18554973175"
};

export const MedicarePhoneNumberConstants = {
    "default": "+18339601544",
    "ACA_VERONICA_ESP_FB_B1_SAMUEL" : "+18775952043",
    "ACA_VERONICA_ESP_FB_B1_ADAM" : "+18776992280",
    "ACA_VERONICA_ESP_META_JMS" : "+18333092931",
    "ACA_VERONICA_ESP_LPC_JMS" : "+18333092931",
    "ACA_VERONICA_ESP_GEO_META_ART" : "+18446550358",
    "ACA_VERONICA_YT_ESP_ART" : "+18446550358",
    "ACA_VERONICA_ESP_ART" : "+18446550358",
    "ACA_CDV_JV3_FB_ADAM":"+18446471346",
    "ACA_JV3_CDV_LEAD_GEN_FB_ADAM":"+18446471346"
};

