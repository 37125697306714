import React, { useState } from 'react'
import Header from '../../Includes/Layouts/SPH_CDV1/Header';
import Footer from '../../Includes/Layouts/SPH_CDV1/Footer';
import FormSPH_CDV1 from '../../Forms/FormSPH_CDV1';
import AdtopiaLoadLP from '../../../Utility/AdtopiaLoadLP';
import SimpleModal from '../SimpleModal';
import { useDataIngestion } from '../../../Hooks/useDataIngestion';
import UnqualifiedRedirectionLinkSpanish from "../../../Utility/UnqualifiedRedirectionLinkSpanish";
import "../../../assets/css/SPH_CDV1/custom.scss";


const SPH_CDV1 = () => {
   
    const {saveDataIngestion} = useDataIngestion();
    const [modalBody, setModalBody] = useState('');
    const [optionValue, setOptionValue] = useState('');
    const query = new URLSearchParams(window.location.search);
  
    const uuid  = query.get("uuid");
    const ModalViewers =  async(click_status) =>{
        const viewersSubmit = await saveDataIngestion(
               uuid,
               [click_status],
               "unqualify_modal",
               "SPH_CDV1",      
        );
        window.location = UnqualifiedRedirectionLinkSpanish(optionValue).redirectionLink;
      }

    return (
        <>
            <div className="SPH_CDV1">
            <AdtopiaLoadLP PageType="LP" splitName="SPH_CDV1" />
            <Header />
            <FormSPH_CDV1 splitName="SPH_CDV1" setOptionValue={setOptionValue} setModalBody={setModalBody} />
            <Footer />
                <SimpleModal
                    modalBody={modalBody}
                    setModalBody={setModalBody}
                    ModalViewers={ModalViewers}
                    ModalButton="Reclamar Beneficios"
                />
            <script src="https://cdn.jsdelivr.net/npm/tsparticles-confetti@2.12.0/tsparticles.confetti.bundle.min.js"></script>

            </div>
        </>

    )
}

export default SPH_CDV1