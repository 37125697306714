import React from "react";
import logo from "../../../../../assets/img/logo.png"
import { Helmet } from "react-helmet";
const Header = () =>{
    return(
        <>
        <Helmet><script src="//b-js.ringba.com/CAfe8e30f0d9964386bac7acabadf4a10b" async></script></Helmet>
         <header>
                <div className="container">
                    <div className="row">
                        <div className="col-12 ">
                            {/* <img src={logo} alt="" /> */}
                        </div>
                    </div>
                </div>
            </header>
        </>
    )
}
export default Header;