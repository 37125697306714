//     extra parameters     //
//     ----------------     //
//  -> first_question       //
//  -> final_question       //
//  -> specialcss           //
//////////////////////////////

export const QandA_SPH_CDVS31 = [
  {
    id: '7',
    first_question: true,
    question: [
      `Hola!`,
      `¿Quieres ver si calificas?`
    ],
    options: [
      { id: '14', text: "Si, Vamos!" },
      // { id: '15', text: "No" },
    ],
  },
  // {
  //   id: '2',
  //   question: [
  //     `¿Ya tienes seguro medico, Medicare o Medicaid?`
  //   ],
  //   options: [
  //     { id: '3', text: "No" },
  //     { id: '4', text: "Si" },
  //   ],
  // },
  // If Si, then below question else skip to last
  // show popup for every below option
  {
    id: '27',
    // question: [`¿Cuál de los siguientes tienes?`],
    question: [`Cual de los siguentes tienes?`],
    specialcss: 'verticleButton',
    options: [
      { id: '60', text: "No tengo Seguro Medico" },
      { id: '61', text: "Obamacare" },
      { id: '62', text: "Medicare" },
      { id: '63', text: "Medicaid" },
    ],
  },
  {
    id: '10',
    question: [`¿Tienes Green Card, eres Ciudano, eres Residente, o tienes un Permiso para Trabajar en EE.UU?`],
    final_question: true,
    options: [
      { id: '22', text: "Si" },
      { id: '23', text: "No" },
      // show popup for no
    ],
  },
  {
    id: 'CALL_CLICK',
    question: [`¡Felicidades! 🤩🤩🥳🥳🥳`,
      `Estás precalificado para recibir hasta $6200 en subsidios fiscales para el seguro médico.`,
      `Puedes utilizar los ahorros para las facturas del hogar, el entretenimiento, los viajes y el ocio!`, `Toca el botón de abajo para llamar ahora y reclamar tus beneficios, solo te llevará de 2 a 3 minutitos.`
    ],
    options: [
      { id: '', text: "833-506-3463" },
    ],
  }
  // ,
  // {
  //   id: 'CALL_CLICK',
  //   question: [`¡Felicidades! 🤩🤩🥳🥳🥳`,
  //     `¡Calificas para una llamada de asesoramiento gratuita para conocer tus opciones y poder obtener un estatus migratorio legal!`,
  //     `Si te gusta lo que escuchas, este socio cuidadosamente seleccionado ofrece sus servicios a precios mucho más bajos que los que obtendrías de un bufete de abogados y estará contigo en cada paso del camino para cambiar tu vida.`,
  //     `Mantendremos la línea abierta durante 60 segundos.`
  //   ],
  //   specialcss: 'timer',
  //   options: [
  //     { id: '', text: "833-506-3463" },
  //   ],
  // },
];


